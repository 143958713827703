import {
    Button,
    Grid,
    GridRow,
    Input,
    Loader,
    Segment,
  } from "semantic-ui-react";

const BuscadorBeneficiario = ({ campoBusqueda, estadoCampoBusqueda, setEstadoCampoBusqueda, onSearch, loading }) => {
  return (
    <Segment>
      <h3>Buscador</h3>
      <Grid>
        <GridRow>
          <Grid.Column
            style={{ paddingRight: "0" }}
            mobile={16}
            tablet={16}
            computer={4}
          >
            <label>Ingrese {campoBusqueda} del beneficiario: </label>
            <Grid.Row>
              <Input
                style={{ marginRight: "5px" }}
                type={"text"}
                value={estadoCampoBusqueda}
                placeholder={`${campoBusqueda} del beneficiario`}
                onChange={(e) => setEstadoCampoBusqueda(e.target.value)}
              />
              <Button
                style={{ minWidth: "100px", maxHeight: "36px" }}
                disabled={loading || estadoCampoBusqueda === ""}
                onClick={(e) => onSearch()}
              >
                {loading ? <Loader active inline size={"mini"} /> : "Buscar"}
              </Button>
            </Grid.Row>
          </Grid.Column>
        </GridRow>
      </Grid>
    </Segment>
  );
};

export default BuscadorBeneficiario;
