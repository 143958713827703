import "./ServicesAdmin.scss";
import { useState } from "react";
import DetalleBeneficiarioMista from "./components/DetalleBeneficiarioMista";
import MistaService from "../../../../services/Mista";
import BuscadorBeneficiario from "../shared/BuscadorBeneficiario";

const BeneficiariosMista = () => {
  const [dni, setDni] = useState("");
  const [beneficiario, setBeneficiario] = useState("");
  const [loading, setLoading] = useState(false);

  async function obtenerBeneficiario() {
    setLoading(true);
    setBeneficiario(await MistaService.obtenerBeneficiario(dni));
    setLoading(false);
  }

  return (
    <>
      <h1>Beneficiarios Mista</h1>
      <BuscadorBeneficiario
        campoBusqueda={"DNI"}
        estadoCampoBusqueda={dni}
        setEstadoCampoBusqueda={setDni}
        onSearch={obtenerBeneficiario}
        loading={loading}
      />

      {<DetalleBeneficiarioMista beneficiario={beneficiario} />}
    </>
  );
};

export default BeneficiariosMista;
