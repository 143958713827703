import {BASE_API} from '../../utils/constantes'
import {getToken} from '../Common/token'

export async function getBeneficiario(request_get){
    try {
        
        const url =  `${BASE_API}/api/beneficiario/?${request_get}`
            
       // console.log("url",url)
        const params = {
            headers:{
                Authorization:`Bearer ${getToken()}`
            }
        }
        const response = await fetch(url,params)
       
        if(response.status === 200){
           
            const result= await response.json()
            console.log(result)
          
            return result
            
        }
        return false
        
    } catch (error) {
        throw new Error ("Credenciales invalidas")
    }
}

export async function getBeneficiarios() {
    try {
        const url = `${BASE_API}/api/clientes/`
        const params = {
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        }
        const response = await fetch(url, params)
        if (response.status === 200) {
            return response.json().then((data) => data.data)
        }
        return []
    } catch (error) {
        throw new Error("Hubo un problema, vuelva a intentar")
    }
}

export async function addBeneficiario(request_post){
    try {
        const url =  `${BASE_API}/api/beneficiario/`
        const params = {
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body: JSON.stringify(request_post)
        }
        const response = await fetch(url,params)
        if(response.status !== 200){
            throw new Error ("Usuario o contraseña incorrectos")
        }
        const result= await response.json()
        return result
    } catch (error) {
        throw error
    }
}