import {Button, Grid, GridRow, Loader, Segment} from "semantic-ui-react";
import DatoBeneficiario from "./DatoBeneficiario";
import axios from "axios";
import {BASE_API} from "../../../../../utils/constantes";
import {useEffect, useState} from "react";

const DetalleBeneficiarioMista = ({ beneficiario='' }) => {
    const btnInicial = {color:'blue', text:'Importar'};

    const [loading, setLoading] = useState(false);
    const [importButton, setImportButton] = useState(btnInicial);

    useEffect(() => {
        setImportButton(btnInicial);
    }, [beneficiario]);

    const importarBeneficiario = async() => {

        console.log("Beneficiario final")
        console.log(beneficiario)

        setLoading(true);
        const {data, status} = await axios.post(`${BASE_API}/api/clientes/`, [beneficiario], {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        }).then((response) => {
            setLoading(false);
            return response;
        }).catch((error) => {
            setLoading(false);
            return error.response;
        });

        if(status !== 200){
            setImportButton({color:'red', text:'Error'});
        } else {
            setImportButton({color:'green', text:'Importado'});
        }
    }
    if(beneficiario){
        return (
            <Segment>
                <GridRow>
                    <Grid.Column style={{paddingLeft:'0'}} mobile={16} tablet={16} computer={12}>
                        <GridRow>
                            <Grid style={{padding:'10px'}}>
                                <Grid.Row style={{alignItems:'baseline', justifyContent:'space-between'}}>
                                    <h3>Información  del Beneficiario</h3>
                                    <Button
                                        style={{margin:0}}
                                        color={importButton.color}
                                        onClick={() => importarBeneficiario()}
                                    >
                                        {loading
                                            ?<Loader active inline size={"mini"} />
                                            :importButton.text}
                                    </Button>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                        <DatoBeneficiario label={"Nombre"} value={beneficiario.nombre}/>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                        <DatoBeneficiario label={"Apellido"} value={beneficiario.apellido}/>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                        <DatoBeneficiario label={"DNI"} value={beneficiario.dni}/>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                        <DatoBeneficiario label={"Fecha de Nacimiento"} value={beneficiario.fecha_nacimiento}/>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                        <DatoBeneficiario label={"Vigencia"} value={beneficiario.vigencia}/>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                        <DatoBeneficiario label={"Vínculo"} value={beneficiario.vinculo}/>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                        <DatoBeneficiario label={"Activo"} value={"Activo"}/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </GridRow>
                    </Grid.Column>
                </GridRow>
            </Segment>
        )
    } else if(beneficiario !== '') {
        return (
            <Segment>
                <GridRow>
                    <Grid.Column style={{paddingLeft:'0'}} mobile={16} tablet={16} computer={12}>
                        <GridRow>
                            <Grid style={{padding:'10px'}}>
                                <Grid.Row style={{alignItems:'baseline', justifyContent:'space-between'}}>
                                    <h3>No se encontraron beneficiarios</h3>
                                </Grid.Row>
                            </Grid>
                        </GridRow>
                    </Grid.Column>
                </GridRow>
            </Segment>
        )
    }

}

export default DetalleBeneficiarioMista